<template>
    <div class="surface-card p-6 pb-5 border-1 surface-border">
        <update-view-header
            headline="Neuen Kunden erstellen"
            button-text="Zurück zur Übersicht"
            path="/customers"
        />

        <update-form
            v-model="editedEntity"
            :errors="editedEntityErrors"
            :show-main-contact="true"
            type="customer"
        />

        <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

        <div class="text-center">
            <Button type="submit" label="Speichern" @click="submitEntity"></Button>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import UpdateView from "@/mixin/UpdateView";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import UpdateForm from "@/components/data/company/UpdateForm";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateForm,
            UpdateViewHeader,
            Button,
        },
        data() {
            return {}
        },
        methods: {
            getCreateObject() {
                return {
                    active: true,
                    isCustomer: true,
                    mainContactPerson: {}
                }
            },
            getApiEndpoint() {
                return 'companies';
            },
            getCreationAction(entity) {
                return this.$router.push('/customers/' + entity.id);
            },
        }
    }
</script>
